import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto, RHIMFleetOverviewServiceV1ModelsVolumesDashboardDto } from '@rhim/rest/fleetOverview';
import { isDefined } from '@rhim/utils';
import React from 'react';
import styled from 'styled-components';

import { DropdownMeasurement } from '../../../components/MeasurementSelectionDropdown/MeasurementSelectionItem';
import Section3DView from './Section3DView';
import SectionCompareTo from './SectionCompareTo';
import SectionCurrent from './SectionCurrent';
import SectionInitial from './SectionInitial';
import SectionSummary from './SectionSummary';

interface Props {
  campaignHavingVolumes: RHIMFleetOverviewServiceV1ModelsVolumesDashboardDto | undefined;
  initialMeasurement: RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto;
  currentMeasurement: undefined | RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto | null;
  setSelectedCompareToMeasurementId: (value: string | undefined) => void;
  selectedCompareToMeasurement?: RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto;
  sortedMeasurementsToCompareTo: DropdownMeasurement[];
}
const ComparisonPanel: React.ChildlessComponent<Props> = ({
  setSelectedCompareToMeasurementId,
  campaignHavingVolumes,
  initialMeasurement,
  currentMeasurement,
  selectedCompareToMeasurement,
  sortedMeasurementsToCompareTo,
}) => {
  const { t } = i18nReact.useTranslation(['volume']);

  const hasAtLeastTwoMeasurements = isDefined(campaignHavingVolumes) && campaignHavingVolumes.measurements.length >= 2;

  return (
    <SWrapper>
      <SHeaderContainer>
        <SHeaderLabelBold>{t('volume:headerVolumeExplorer')}</SHeaderLabelBold>
        <SHeaderLabelRegular>{`${t('volume:infoText')} ${isDefined(campaignHavingVolumes) ? campaignHavingVolumes.campaign : ''}`}</SHeaderLabelRegular>
      </SHeaderContainer>
      <SSectionsContainer>
        <SectionInitial initialMeasurement={initialMeasurement} />
        {hasAtLeastTwoMeasurements && currentMeasurement !== null && (
          <>
            <SBorderedContainer>
              <SectionCurrent currentMeasurement={currentMeasurement} />
              <SectionCompareTo
                setSelectedCompareToMeasurementId={setSelectedCompareToMeasurementId}
                currentMeasurement={currentMeasurement}
                campaignHavingVolumes={campaignHavingVolumes}
                selectedCompareToMeasurement={selectedCompareToMeasurement}
                sortedMeasurementsToCompareTo={sortedMeasurementsToCompareTo}
              />
            </SBorderedContainer>
            <SectionSummary initialMeasurement={initialMeasurement} currentMeasurement={currentMeasurement} campaignHavingVolumes={campaignHavingVolumes} />
          </>
        )}
        <SHorizontalSpring />
        {isDefined(currentMeasurement) && <Section3DView measurement={currentMeasurement} />}
      </SSectionsContainer>
    </SWrapper>
  );
};

const SWrapper = styled.div`
  background-color: ${settings.colors.Monochromatic.White};
  border-radius: 5px;
  padding: ${settings.Spacing.Spacing_400};
  display: flex;
  flex-direction: column;
`;

const SHeaderContainer = styled.div`
  display: flex;
  column-gap: ${settings.Spacing.Spacing_150};
`;

const SHeaderLabelRegular = styled.span`
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.XX_Large};
  color: ${settings.colors.Primary.Grey_8};
`;

const SHeaderLabelBold = styled(SHeaderLabelRegular)`
  font-family: ${settings.typography.FontFamily.Bold};
`;

const SSectionsContainer = styled.div`
  margin-top: ${settings.Spacing.Spacing_300};
  display: flex;
  padding: 1px;
`;

const SBorderedContainer = styled.div`
  display: flex;
  border-radius: 3px;
  position: relative;
  margin-top: -1px;
  border: 1px solid ${settings.colors.Primary.Blue_3};
`;

const SHorizontalSpring = styled.span`
  flex-grow: 1;
`;

ComparisonPanel.whyDidYouRender = true;
export default React.memo(ComparisonPanel);
