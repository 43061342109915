import { ArcRotateCamera, BaseTexture, DynamicTexture, Nullable, PointerEventTypes, PointerInfo, Scene } from '@babylonjs/core';
import { CustomMaterial } from '@babylonjs/materials';
import { ColorScales, head, isDefined, last, VOLUME_LINE_COLOR } from '@rhim/utils';
import { NULL_THICKNESS_COLOR } from '@rhim/utils';
import { TableLikeData } from 'typings/internal/sections';

import { generateClusteredTexture, updateColorScaleTexture } from './renderingUtils';
import type { LadleMaterialOptions } from './types';

export const TEXTURE_COLOR_SCALES = 'textureColorScales';
export const TEXTURE_CLUSTERS = 'textureClusters';

export enum TextureCoordinatesIndex {
  Default = 0,
  CylindricalCoordinates = 1,
}

function createDummyTexture(name: string, scene: Scene): DynamicTexture {
  const texture = new DynamicTexture(name, { width: 1, height: 1 }, scene);
  texture.coordinatesIndex = TextureCoordinatesIndex.CylindricalCoordinates;
  const ctx = texture.getContext();
  ctx.fillStyle = 'transparent';
  ctx.fillRect(0, 0, 1, 1);
  texture.update();
  return texture;
}

export class UniversalLadleMaterial extends CustomMaterial {
  private dynamicTextureColorScales: DynamicTexture;
  private dynamicTextureClusters: DynamicTexture;
  private emptyTexture: DynamicTexture;
  private initOptions: LadleMaterialOptions;
  private minThickness = -Infinity;
  public clippingYLevel = Infinity;

  public constructor(name: string, scene: Scene, options: LadleMaterialOptions) {
    super(name, scene);
    this.initOptions = options;
    this.emptyTexture = createDummyTexture('empty_texture', scene);
    this.dynamicTextureColorScales = new DynamicTexture(TEXTURE_COLOR_SCALES, { width: 1, height: 1 });
    this.dynamicTextureColorScales.coordinatesIndex = TextureCoordinatesIndex.CylindricalCoordinates;
    this.dynamicTextureClusters = new DynamicTexture(TEXTURE_CLUSTERS, { width: 1, height: 1 });
    this.dynamicTextureClusters.coordinatesIndex = TextureCoordinatesIndex.CylindricalCoordinates;
    this.setupMaterial();
    this.backFaceCulling = false;
    this.specularColor.set(0.05, 0.05, 0.05);
  }

  /**
   * Set a color scale to draw colors corresponding to the thickness map
   * ColorScale expected to be ASC sorted
   *
   * Set updateColorScale(null) to ignore colorization and show other colors like vertex color or textures
   *
   * @param colorScales
   */
  public updateColorScale(colorScales: ColorScales | null) {
    if (colorScales === null) {
      this.dynamicTextureColorScales.clear();
      this.dynamicTextureColorScales.scaleTo(1, 1);
      this.dynamicTextureColorScales.update();
    } else {
      updateColorScaleTexture(this.dynamicTextureColorScales, colorScales);
      this.onBindObservable.addOnce(() => {
        this.getEffect().setFloat('cScaleMinValue', head(colorScales).threshold);
        this.getEffect().setFloat('cScaleMaxValue', last(colorScales).threshold);
      });
    }
  }

  public enableVolumeDepthLine(isEnabled: boolean) {
    this.onBindObservable.addOnce(() => {
      this.getEffect().setBool('volumeDepthEnabled', isEnabled);
    });
  }

  public setVolumeDepthPosition(heightPosition: number) {
    this.onBindObservable.addOnce(() => {
      this.getEffect().setFloat('volumeHeight', heightPosition);
    });
  }

  public updateMinThicknessValue(minThickness: number) {
    this.minThickness = minThickness;
  }

  public updateClusters(clusters: TableLikeData, colorScales: ColorScales) {
    generateClusteredTexture(this.dynamicTextureClusters, clusters, colorScales);
    this.setDiffuseTexture(this.dynamicTextureClusters);
  }

  public setDiffuseTexture(texture: Nullable<BaseTexture>) {
    const dummyTexture = this.emptyTexture.isReady() ? this.emptyTexture : null;
    this.diffuseTexture = isDefined(texture) ? texture : dummyTexture;
    this.onBindObservable.addOnce(() => {
      this.getEffect().setFloat('clippingYLevel', this.clippingYLevel);
    });
  }

  public setClippingHeight(clippingYLevel: number) {
    this.clippingYLevel = clippingYLevel;
  }

  public dispose() {
    this.dynamicTextureColorScales.dispose();
    this.dynamicTextureClusters.dispose();
    super.dispose();
  }

  private setupMaterial() {
    const defineThicknessMap = this.initOptions.withThicknessMap ?? false ? '#define THICKNESS_MAP' : '';
    const defineMarkers = this.initOptions.withMarkers ?? false ? '#define MARKERS' : '';
    const defineVolumeLine = this.initOptions.withVolumeLine ?? false ? '#define SHOW_VOLUME_LINE' : '';

    this.Vertex_Definitions(`
      ${defineThicknessMap}
      ${defineMarkers}
      ${defineVolumeLine}
      #ifdef THICKNESS_MAP
        attribute float thickness;
        varying float vThickness;
        varying float v_isNanThickness;
      #endif
      varying float correctUV;
    `);

    this.Vertex_MainBegin(`
      #ifdef THICKNESS_MAP
        vThickness = thickness;
        v_isNanThickness = float(isnan(thickness));
      #endif

      #ifdef MAINUV2
        correctUV = 1. - step(0.01, uv2.x) + step(0.99, uv2.x);
      #else
        correctUV = 0.;
      #endif
    `);

    this.Fragment_Definitions(`
      ${defineThicknessMap}
      ${defineMarkers}
      ${defineVolumeLine}
      #define TAU 6.283185307179586
      #define HALF_LINE_WIDTH 0.0015
      #define HALF_LINE_WIDTH_VOLUME 0.0035
      #define SMOOTHING_DELTA 0.001
      #define ROTATION_ANGLE_BLUE_DOT_RADIUS .003
      #define DASH_GRADATION 500.
      #define LEVEL_OF_PROJECTION_TO_XZ_AXIS 0.6

      #ifdef THICKNESS_MAP
        varying float vThickness;
        varying float v_isNanThickness;
      #endif

      varying float correctUV;

      const vec4 COLOR_WHITE = vec4(1.0);
      const vec4 COLOR_BLUE = vec4(0.0, 0.196, 0.384, 1.0);
    `);

    this.Fragment_MainBegin(`
      #ifdef THICKNESS_MAP
        if (vThickness < minThickness) {
          discard;
        }
      #endif
      if (vPositionW.y > clippingYLevel) {
        discard;
      }
    `);

    /**
     * By convention `atan` is positive in 1st 2 quarts and negative slop in 2d 2 quarts
     * that's why I add 360 degree to the angle and take a modulo to find an exact angle
     *
     * To skip applying a world matrix to each position vector I added 90 degree rotation to the angle:
     * `float angle = atan(vPositionW.z, vPositionW.x) + 1.25 * TAU;`
     * 1.25 * TAU in other words is 360 + 90 offset
     */
    this.Fragment_Custom_Diffuse(`
      vec3 selectedColor = vec3(0.);
      #if defined(VERTEXCOLOR)
          selectedColor = vColor.rgb;
      #endif

      selectedColor = mix(selectedColor, baseColor.rgb, baseColor.a);

      #ifdef THICKNESS_MAP
        float scaleU = (vThickness - cScaleMinValue) / (cScaleMaxValue - cScaleMinValue);
        scaleU = clamp(scaleU, 0., 1.);

        vec4 colorT = texture2D(${TEXTURE_COLOR_SCALES}, vec2(scaleU, 1.));
        selectedColor = mix(selectedColor, colorT.rgb, colorT.a);
        float steppedIsNanThickness = step(0.00001, v_isNanThickness);
        selectedColor = mix(selectedColor, noValueColor, steppedIsNanThickness);
      #endif

      #ifdef DIFFUSE
        if (correctUV > 0.5) {
          float angle = atan(vPositionW.z, vPositionW.x) + 1.25 * TAU;
          angle = mod(angle, TAU);
          float u = angle / TAU;
          baseColor = texture2D(diffuseSampler, vec2(u, vDiffuseUV.y) + uvOffset);
        } else {
          baseColor = texture2D(diffuseSampler, vDiffuseUV + uvOffset);
        }
        baseColor.rgb = mix(selectedColor, baseColor.rgb, baseColor.a);
      #else
        baseColor = vec4(selectedColor, 1.);
      #endif

      #ifdef MARKERS
        float radius = length(vPositionW.xz);

        float directionStrength = dot(normalize(vec3(vPositionW.xz, 0.)), normalize(vPositionW.xzy));
        float directionStep = max(step(LEVEL_OF_PROJECTION_TO_XZ_AXIS, directionStrength), step(0., vPositionW.y));
        float stepZ = step(0.0, sin(vPositionW.z * DASH_GRADATION));
        float stepY = step(0.0, sin(vPositionW.y * DASH_GRADATION));
        float whiteColorLevel = 1. - step(0., vPositionW.z);
        whiteColorLevel = whiteColorLevel
          * (smoothstep(-HALF_LINE_WIDTH - SMOOTHING_DELTA, -HALF_LINE_WIDTH, vPositionW.x) - smoothstep(HALF_LINE_WIDTH, HALF_LINE_WIDTH + SMOOTHING_DELTA, vPositionW.x));
        whiteColorLevel = whiteColorLevel * mix(stepZ, stepY, directionStep);

        baseColor = mix(baseColor, COLOR_WHITE, whiteColorLevel);

        if(isShowingRotationAngle) {
          float radius = length(vPositionW.xz);
          float blueDot = step(radius, ROTATION_ANGLE_BLUE_DOT_RADIUS);

          float angleFromCenter = atan(vPositionW.z, vPositionW.x);
          float new_z = radius * sin(angleFromCenter - rotationAngle);
          float new_x = radius * cos(angleFromCenter - rotationAngle);
          float delta = fwidth(new_z);
          float blueLine = step(0., new_x);
          blueLine = blueLine * (smoothstep(-HALF_LINE_WIDTH - delta, -HALF_LINE_WIDTH, new_z) - smoothstep(HALF_LINE_WIDTH, HALF_LINE_WIDTH + delta, new_z));

          baseColor = mix(baseColor, COLOR_BLUE, blueDot);
          baseColor = mix(baseColor, COLOR_BLUE, blueLine);
        }
      #endif

      #ifdef SHOW_VOLUME_LINE
        if (volumeDepthEnabled) {
          vec4 underVolumeColor = mix(baseColor, vec4(1.0), 0.4);
          float isInVolumeZone = step(vPositionW.y, volumeHeight);
          baseColor = mix(baseColor, underVolumeColor, isInVolumeZone);


          float smoothness = fwidth(vPositionW.y);
          float e0 = volumeHeight - HALF_LINE_WIDTH_VOLUME;
          float e1 = volumeHeight + HALF_LINE_WIDTH_VOLUME;
          float lineFactor = smoothstep(e0 - smoothness, e0 + smoothness, vPositionW.y) -
                             smoothstep(e1 - smoothness, e1 + smoothness, vPositionW.y);
          baseColor = mix(baseColor, vec4(1.0), lineFactor);
        }
      #endif

    `);

    this.AddUniform('clippingYLevel', 'float', null);
    this.onBindObservable.add(() => {
      // setting initial clippingYLevel to a high level to avoid clipping on load
      this.getEffect().setFloat('clippingYLevel', this.clippingYLevel);
    });
    this.AddAttribute('thickness');
    this.AddUniform('minThickness', 'float', 0);
    this.onBindObservable.add(() => {
      this.getEffect().setFloat('minThickness', this.minThickness);
    });
    this.AddUniform(TEXTURE_COLOR_SCALES, 'sampler2D', this.dynamicTextureColorScales);
    this.AddUniform('cScaleMinValue', 'float', 0);
    this.AddUniform('cScaleMaxValue', 'float', 0);
    this.AddUniform('volumeHeight', 'float', 0);
    this.AddUniform('noValueColor', 'vec3', NULL_THICKNESS_COLOR);
    this.AddUniform('volumeLineColor', 'vec3', VOLUME_LINE_COLOR);
    if (this.initOptions.withVolumeLine ?? false) {
      this.AddUniform('volumeDepthEnabled', 'bool', false);
    }

    if (this.initOptions.withMarkers ?? false) {
      // TODO get the value from a projection which should be precalculated in the shader
      let isShowingRotationAngle = false;
      const scene = this.getScene();

      this.AddUniform('rotationAngle', 'float', null);
      this.AddUniform('isShowingRotationAngle', 'bool', isShowingRotationAngle);

      const rotationCallback = () => {
        this.getEffect().setBool('isShowingRotationAngle', isShowingRotationAngle);
        this.getEffect().setFloat('rotationAngle', (scene.activeCamera as ArcRotateCamera).alpha);

        if (!isShowingRotationAngle) {
          this.onBindObservable.removeCallback(rotationCallback);
        }
      };

      const pointerObserver = scene.onPointerObservable.add((pointerEvent: PointerInfo) => {
        switch (pointerEvent.type) {
          case PointerEventTypes.POINTERUP: {
            isShowingRotationAngle = false;
            break;
          }
          case PointerEventTypes.POINTERDOWN: {
            isShowingRotationAngle = true;
            this.onBindObservable.add(rotationCallback);
            break;
          }
        }
      });

      this.onDisposeObservable.addOnce(() => {
        scene.onPointerObservable.remove(pointerObserver);
      });
    }
  }
}
